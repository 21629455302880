.ir-ws-course-list-image {
  width: 30px;
  height: 30px;
  margin-left: 15px;
  object-fit: cover;
}
.ir-ws-create-course-delete-all-container {
  position: absolute;
  top: 75px;
  right: 40px;
  z-index: 10;
  p {
    margin: 0;
    border-radius: 5px;
    padding: 5px 15px;

    background-color: #6842ef;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background-color: #8668f2;
    }
  }
}

// .ir-ws-course-delete-checkbox {
//   // -webkit-appearance: none;
//   // -moz-appearance: none;
//   // appearance: none;
//   width: 20px;
//   height: 20px;
//   margin-left: 15px;
//   &:checked {
//     background-color: #6842ef;
//   }
// }

.checkbox {
  display: inline-block;
  position: relative;
  // margin: 0 0 10px;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 22px;
  margin-left: 15px;
}
.checkbox__input {
  position: absolute;
  top: 4px;
  left: 0;
  width: 16px;
  height: 16px;
  opacity: 0;
  z-index: 0;
}
.checkbox__label {
  display: block;
  padding: 0 0 0 15px;
  cursor: pointer;
  margin: 0;
}
.checkbox__label:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 2px;
  z-index: 1;
  transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background-color, border-color;
}
.checkbox__label:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 6px;
  height: 12px;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: rotate(45deg);
  z-index: 2;
  transition: border-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.checkbox__input:checked + .checkbox__label:before {
  background-color: #6842ef;
  border-color: #6842ef;
}
.checkbox__input:checked + .checkbox__label:after {
  border-color: #fff;
}


.draft-list {
  margin: 20px;

  .draft-title {
    margin-bottom: 15px;
    font-size: 24px;
    color: #333;
  }

  .draft-list-items {
    list-style: none;
    padding: 0;

    .draft-item {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      padding: 10px;
      border: 1px solid #ddd;
      background-color: #f9f9f9;
      border-radius: 5px;
      transition: background-color 0.5s;

      &:hover {
        // background-color: #E5E7EA;
      }

      .draft-item-title {
        margin: 0;
        flex: 1;
        font-size: 18px;
        color: #444;
        text-transform: capitalize;
      }

      .edit-btn,
      .delete-btn {
        margin-left: 15px;
        padding: 5px 10px;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        transition: background-color 0.5s;

        &:hover {
          background-color: #ddd;
        }
      }

      .edit-btn {
        background-color: #5F50F1;
        color: white;

        &:hover {
          background-color: #4032bd;
        }
      }

      .delete-btn {
        background-color: #f44336;
        color: #FFF;

        &:hover {
          background-color: #ca2220;
        }
      }
    }
  }
}
