.ir-chat-profile-main-container {
  width: 35%;
  background-color: #dcdfe3;
  --webkit-box-orient: vertical;
  --webkit-box-direction: normal;
  --ms-flex-direction: column;
  flex-direction: column;
  padding: 16px 16px 16px 16px;
  display: var(--webkit-box);
  display: var(--ms-flexbox);
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  // overflow-y: auto;
  // padding-top: 57px;
  // padding-top: 16px;

  position: relative;
  max-width: 530px;

  //   border: 2px solid red;

  .ir-chat-profile-inner-container {
    // height: 100%;
    // -webkit-box-flex: 1;
    // -ms-flex: 1;
    // flex: 1;
    position: relative;
    overflow: auto;
    padding-bottom: 1.5rem;
    // border: 2px solid red;
  }

  .ir-chat-profile-details-container {
    text-align: center;
    position: relative;
    background-color: #fff;
    margin-top: 50px;
    border-radius: 10px;

    .ir-profile-info-container {
      padding: 40px 15px 20px;

      .ir-chat-profile-title {
        font-family: Poppins-Regular;
        font-size: 14px;
        margin-top: 10px;
      }

      .ir-chat-profile-email {
        margin: 8px 0 0 0;

        .ir-profile-email-title {
          font-family: Poppins-Regular;
          font-size: 14px;
        }

        .ir-profile-email-content {
          color: #47bd94;
        }
      }
    }

    .ir-chat-profile-pic {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(-50px);
    }

    .ir-chat-profile-name {
      font-size: 18px;
      font-family: Poppins-Medium;
      font-weight: 600;
      margin-top: 10px;
    }
  }

  .iks-carousel-inactive {
    display: none;
  }

  .iks-cine-content {
    text-align: center;
    margin-bottom: 10px;
    height: 350px;
    position: relative;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .iks-carousel-active {
    display: block;
    cursor: pointer;
    background-color: #c4c4c4;
    border-radius: 10px;
    margin: 0 auto;

    // border: 2px solid red;
    position: relative;
    // margin-top: 50px;

    .chat-img-edit-btn {
      position: absolute;
      top: 20px;
      right: 8px;
      z-index: 99;
    }
  }

  .ir-chat-profile-inner-container {
    .ir-car-item-container {
      // margin-right: 10px;
    }
  }
  .ir-car-item-container {
    display: inline-block;
    // margin-top: 10px;

    img {
      border-radius: 10px;
      cursor: pointer;
    }
  }

  .ir-car-item-img {
    // width: 100px;
    width: 90px;
    height: 90px;
    object-fit: cover;
    // height: 75px;
    background-color: #cecece;
    border: 1px solid #cecece;
    &:hover {
      border: 1px solid #6842ef;
    }
  }

  .active-image-border {
    border: 1px solid #6842ef;
  }

  img.ir-car-img {
    max-width: 100%;
    max-height: 100%;
    // border: 1px solid rgba(0,0,0,.15);
    // padding: 5px;
    border-radius: 10px;
  }

  .ir-car-update-btn-container {
    position: absolute;
    top: 10px;
    right: 28px;

    // .btn-round-update {
    //   border-radius: 10px;
    //   width: 40px;
    //   height: 40px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   background-color: #6842ef;
    //   border: 0;

    // //   &:before {
    // //     content: "";
    // //     position: absolute;
    // //     transform: scaleX(0);
    // //     transform-origin: 35% 100%;
    // //     width: inherit;
    // //     height: inherit;
    // //     border-radius: 50%;
    // //     background: rgb(43, 167, 255);
    // //     background: linear-gradient(
    // //       90deg,
    // //       rgba(43, 167, 255, 1) 0%,
    // //       rgba(104, 66, 239, 1) 100%
    // //     );
    // //     transition: transform 0.8s, background-color 0.8s;
    // //   }

    // //   &:hover::before {
    // //     transform: scaleX(1);
    // //   }

    //   i {
    //     z-index: 1;
    //     font-size: 15px;
    //     transition: transform 0.3s ease-in-out;

    //     &:hover {
    //         // background-color: #6842ef;
    //         transform: scale(1.2);
    //       }
    //   }

    // //   &:hover {
    // //     background-color: #6842ef;
    // //   }
    // }

    .btn-round-update {
      border-radius: 10px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #6842ef;
      border: 0;
      cursor: pointer;

      i {
        z-index: 1;
        font-size: 15px;
        transition: transform 0.3s ease-in-out;

        .btn-round-update:hover & {
          transform: scale(1.2);
        }
      }
    }
  }

  .ir-image-preview-main-modal {
    .ir-modal-thumb-container-large-image {
      height: calc(100vh - 250px);
      position: relative;

      .iks-carousel-active {
        background: transparent;
      }

      .iks-cine-content {
        height: 100%;

        .ir-car-img {
          position: absolute;
          left: 50%;
          max-width: 100%;
          max-height: 100%;
          transform: translateX(-50%) translateY(-50%);
          background-color: #fff;
          top: 50%;
        }
      }
    }

    .ir-car-item-container {
      text-align: center;

      img {
        // width: 90px !important;
      }
    }
  }
}

.search-overlay {
  position: absolute;
  top: 75px;
  right: 178px;
  width: 100%;
  max-width: 400px;
  height: auto;
  max-height: 500px;
  background-color: #cdbfff;
  z-index: 10;
  border-radius: 10px;
}

.overlay-content {
  padding: 20px 40px;
}

.highlight {
  color: #007aff;
  font-weight: bold;
}

/* Styles for chat history */
.chat-history {
  padding: 20px;
  height: 100%;
  height: auto;
  max-height: 500px;
  overflow-y: scroll;
  border-radius: 10px;
  background-color: #cdbfff;
}

.message-item {
  background-color: #cdbfff;
  border-bottom: 1px solid #ababab;
  display: flex;
  flex-direction: column;
  padding: 0 4px;
  // background: #FFF;
  transition: background-color 0.3s ease;

  cursor: pointer;
  &:hover {
    background-color: #dcdfe3;
  }
}

.message-item:last-child {
  border-bottom: none;
}

.message-user {
  font-weight: 400;
  color: #333;
  margin-bottom: 4px;
  font-size: 10px;
}

.message-content {
  color: #555;
  margin-bottom: 4px;
  font-size: 12px;
  // background-color: #FFF;
  padding: 0 4px;
}

.message-time {
  font-size: 12px;
  font-weight: 500;
  color: #212521;
  text-align: right;
}

.no-results {
  text-align: center;
  color: #212521;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  // font-style: italic;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 40px;
  font-weight: 400;
}

.ir-chat-convo-msg-search {
  padding: 0.4rem 1rem;
  margin-right: 20px;
  background: transparent;

  &:focus {
    outline: 1px solid #6842ef;
  }
}

.group-info-overlay {
  // padding: 20px;
  // background: #fff;
  // border-radius: 10px;
  // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #dcdfe3;
  margin-top: 16px;

  .group-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 20px;

    .group-header-image {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      object-fit: cover;
    }

    .group-header-name {
      font-size: 22px;
      font-weight: 500;
      margin-top: 8px;
      margin-bottom: 0;
    }
    .group-header-des {
      font-size: 16px;
      // font-weight: 500;
      // margin-top: 0px;
    }
  }

  .group-users-list {
    margin-top: 10px;
    .user-item {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;

      .user-avatar {
        font-weight: 700;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        object-fit: cover;
        border: 2px solid #ffffff;
      }

      .user-name {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .no-users {
      font-size: 14px;
      color: #666;
    }
  }
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.shimmer {
  background: linear-gradient(to right, #f0f0f0 0%, #d9d9d9 50%, #f0f0f0 100%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

.shimmer-text {
  width: 120px;
  height: 16px;
  margin: 8px 0;
}

.shimmer-user-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  .shimmer-avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  .shimmer-name {
    width: 120px;
    height: 16px;
  }
}

.shimmer-user-item .shimmer-avatar,
.shimmer-user-item .shimmer-name {
  display: inline-block;
}

.groupInfo-title {
  font-size: 16px;
}

.media-thumbnails-container {
  margin: 1rem 0;
  .media-content-wrapper {
    // display: flex;
    // border: 2px solid red;
    position: relative;
  }

  .thumbnails {
    display: flex;
    gap: 0.5rem;

    .thumbnail {
      width: 80px;
      height: 80px;
      overflow: hidden;
      border-radius: 8px;

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .view-more-btn {
    //   margin-top: 0.5rem;
    //   padding: 0.5rem 1rem;
    //   background-color: #007bff;
    color: #000;
    //   border: none;
    //   border-radius: 4px;
    cursor: pointer;

    position: absolute;
    right: 0;
    top: -17px;
    font-weight: 600;
    opacity: 0.8;
    font-size: 16px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.media-documents-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  .modal-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background: #f1f1f1;

    .tab-btn {
      flex: 1;
      padding: 0.5rem;
      background: transparent;
      border: none;
      font-weight: bold;
      cursor: pointer;

      &.active {
        background: #007bff;
        color: #fff;
      }
    }

    .close-btn {
      font-size: 1.5rem;
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }

  .modal-content {
    padding: 1rem;
    max-height: 400px;
    overflow-y: auto;

    .media-item,
    .document-item {
      margin-bottom: 1rem;
    }
  }
}

.ir-md-overlay {
  z-index: 10;
  margin-top: 16px;
  overflow: auto;

  .ir-md-overlay-content {
    //   background: #fff;
    width: 100%;
    //   max-width: 800px;
    border-radius: 8px;
    //   padding: 20px;
    position: relative;

    .ir-md-close-btn {
      position: absolute;
      // top: -50px;
      top: -5px;
      right: 0px;
      background: none;
      border: none;
      font-size: 38px;
      cursor: pointer;
    }

    .ir-md-tabs {
      display: flex;
      justify-content: space-around;
      margin-bottom: 10px;

      .ir-md-tab-btn {
        flex: 1;
        padding: 10px;
        text-align: center;
        cursor: pointer;
        border: none;
        outline: none;
        border-radius: 5px;
        background: #cdbfff;
        color: #fff;
        //   border-bottom: 2px solid transparent;
        //   transition: all 0.5s ease-in-out;

        &.ir-md-active-tab {
          // border-bottom: 2px solid #007bff;
          // font-weight: bold;
          background: #6842ef;
          color: #ffffff;
          transition: all 0.5s ease-in;
        }
      }
    }

    .ir-md-tab-content {
      height: 100%;
      // overflow-y: auto;

      .ir-md-media-item,
      .ir-md-document-item {
        margin-bottom: 15px;
      }

      .ir-md-media-thumbnail {
        width: 100%;
        // max-width: 300px;
        // width: 80px;
        // height: 80px;
        object-fit: cover;
        // height: auto;
      }
    }
  }
}

.ir-md-media-thumbnails-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(80px, 1fr)); /* Dynamic columns */
  row-gap: 30px; /* Adjust spacing between items */
  justify-items: center; /* Center items within their grid cells */
  max-height: 200px;
  overflow-y: auto;
  margin-top: 30px;

  .ir-md-thumbnail {
    position: relative;
    // width: 100%;
    // height: 100px;
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 10px;
    transition: all 0.3s;
    cursor: pointer;
    border: 1px solid #cecece;
    &:hover {
      border: 1px solid #6842ef;
    }
    // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      // transform: scale(1.02);
      transition: transform 0.3s ease-in;
    }

    // Play icon for videos
    .ir-md-play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      font-size: 40px;
      color: white;
      opacity: 0.7;
      pointer-events: none;
      transition: opacity 0.3s ease-in;
    }

    // Hover effect to show play icon and scale the thumbnail
    &:hover .ir-md-play-icon {
      opacity: 1;
    }
  }

  .ir-md-thumbnail-active {
    border: 1px solid #6842ef;
  }

  // .ir-md-view-more-btn {
  //   margin-top: 10px;
  //   background-color: #007bff;
  //   color: #fff;
  //   border: none;
  //   padding: 5px 10px;
  //   cursor: pointer;
  //   border-radius: 4px;

  //   &:hover {
  //     background-color: #0056b3;
  //   }
  // }
}

.ir-md-media-content-wrapper {
  display: flex;
  flex-direction: column;

  .ir-md-thumbnails {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .ir-md-thumbnail {
      width: 100px;
      height: 100px;
      overflow: hidden;
      border-radius: 8px;

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      // Play icon for videos
      .ir-md-play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
        color: white;
        opacity: 0.7;
        pointer-events: none;
        transition: opacity 0.3s ease;
      }

      // Hover effect to show play icon and scale the thumbnail
      &:hover .ir-md-play-icon {
        opacity: 1;
      }

      &:hover {
        transform: scale(1.03);
        transition: transform 0.3s ease;
      }
    }
  }
}

.ir-md-document-content {
  margin-top: 20px;

  .ir-md-document-item {
    margin-bottom: 15px;

    a {
      display: flex;
      align-items: center;
      color: #007bff;
      text-decoration: none;
      font-weight: bold;

      i {
        margin-left: 5px;
        font-size: 16px;
      }

      &:hover {
        color: #0056b3;
      }
    }
  }

  p {
    margin: 0px;
    padding: 0px;
    // font-weight: 700;
    color: #424242;
    text-align: center;
    margin: 16px 0px;
  }
}

.iks-mint-cust-modal {
  z-index: 99999;
  &.ir-chat-inside-modal-container {
    &.ir-image-preview-main-modal {
      .iks-mint-modal-container {
        background-color: rgba(28, 28, 28, 0.19);
        width: 100%;
        max-width: none;
        display: block;
        height: 100%;
        overflow: hidden;
        padding: 20px;
        backdrop-filter: blur(8px);

        .ir-image-large-thumb-title-container {
          display: flex;
          width: 100%;
          justify-content: space-between;

          h3 {
            font-size: 24px;
            color: #fff;
            text-transform: uppercase;
          }

          .ir-image-head-thumb-right {
            button {
              background-color: #cdbfff;
              border: 0;
              color: #000;
              font-size: 20px;
              height: 40px;
              width: 40px;
              border-radius: 50%;
              transition: all 0.3s;
              &:hover {
                // opacity: 50%;
                background-color: #6842ef;
              }
              i {
                // line-height: 2;
                color: #fff;
              }
            }
          }
        }

        .ir-modal-thumb-container-large-image {
          height: calc(100vh - 250px);
          position: relative;

          .chat-image,
          video {
            position: absolute;
            left: 50%;
            max-width: 100%;
            max-height: 100%;
            transform: translateX(-50%) translateY(-50%);
            background-color: #c4c4c4;
            top: 50%;
            width: 50%;
            object-fit: contain;
            border-radius: 10px;
            @media (max-width: 768px) {
              transform: translateX(-50%) translateY(-40%);
              width: 100%;
            }
          }
        }

        .iks-carousel-inactive {
          display: none;
        }
        .ir-thumbanil-modal-image {
          display: flex;
          justify-content: center;
          width: 100%;
          position: absolute;
          bottom: 0;
          margin-bottom: 30px;
          overflow-x: auto;
          .ir-car-item-container {
            // padding: 7px;
            margin-right: 10px;
            background-color: rgba($color: #6842ef, $alpha: 0.3);
            border-radius: 7px;
            border: 2px solid rgba($color: #6842ef, $alpha: 0.3);
            cursor: pointer;
            flex-shrink: 0;
            overflow: hidden;
            &:hover {
              border: 2px solid rgb(193, 155, 255);
            }

            &.iks-carousel-item-active {
              border: 2px solid rgb(193, 155, 255);
            }

            img,
            video {
              width: 60px;
              height: 60px;
              display: block;
              object-fit: cover;
              border-radius: 10px;
            }

            .ir-video-icon-container {
              position: relative;
              i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                font-size: 25px;
                color: #fff;
                background: #6842ef;
                border-radius: 50%;
                width: 25px;
                height: 25px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}

.all-voters-container {
  position: relative;
  // margin-top: -38px;
  margin-top: 16px;

  .all-voters-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cfcfcf;
    padding-bottom: 8px;
    margin-bottom: 16px;

    .all-voters-title {
      font-size: 18px;
      font-weight: 600;
      // color: #212521;
    }

    .close-icon {
      background: none;
      border: none;
      font-size: 34px;
      font-weight: 500;
      color: #212521;
      cursor: pointer;
      margin-top: -15px;
      // transition: color 0.2s;

      // &:hover {
      //     color: #555;
      // }
    }
  }

  .voter-list-option {
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    background: rgba(204, 204, 204, 0.8);

    &:last-child {
      border-bottom: none;
    }

    .option-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 8px;
      .option-title-text {
        font-size: 16px;
        font-weight: 500;
      }
      .votes-count {
        background-color: #cdbfff;
        color: #0c1317;
        border-radius: 4px;
        padding: 2px 6px;
        font-size: 10px;
      }
    }

    .voter-list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 8px;

      .voter-item {
        display: flex;
        align-items: center;
        gap: 8px;

        .voter-profile-pic {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          object-fit: cover;
          text-align: center;
          // margin: 0;
          line-height: 30px;
          background-color: #47bc94;
          border-radius: 50%;
          border: 2px solid #fff;
          color: #ffffff;
          font-family: Poppins-Bold;
          // position: absolute;
        }

        .voter-username {
          font-size: 15px;
          color: #212521;
        }
      }

      .no-votes {
        font-size: 14px;
        color: #999;
      }
    }
  }
}

.ir-md-overlay .ir-md-overlay-content .ir-ws-chat-media-btn {
  gap: 16px;
  position: sticky;
  top: 0px;
  background: #dcdfe3;
}

.ir-md-media-content .ir-md-media-content-main-img-box {
  .ir-md-media-case-text {
    margin: 0;
    padding: 0px;
    font-weight: 700;
    color: #424242;
  }
  .ir-md-media-image-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    .ir-md-media-image-box {
      // width: 70%;
      // height: 70%;
      width: 250px;
      height: 250px;
      border-radius: 10px;
      background: #c4c4c4;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .ir-md-media-main-image {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }
}

.ir-md-media-video-cont {
  .ir-md-video-dash-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin: 20px 0;
    .ir-md-video-dash {
      width: 100%;
      // height: 1px;
      // background: #cdbfff;
      border-top: 1px solid #cdbfff;
      border-top-style: dotted;
    }
    .ir-md-media-video-text {
      margin: 0px;
      padding: 0px;
      font-weight: 700;
      color: #424242;
    }
  }
  .ir-md-media-video-text {
    margin: 0px;
    padding: 0px;
    font-weight: 700;
    color: #424242;
  }

  .ir-md-media-video-thumbnails-container {
    display: grid;
    grid-template-columns: repeat(4, minmax(80px, 1fr));
    overflow-x: hidden;
    // grid-template-rows: repeat(4, auto);
    row-gap: 30px;
    justify-items: center;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 20px;
    .ir-md-video-thumbnail-box {
      .ir-md-video-thumbnail-parent-box {
        position: relative;
        width: 80px;
        height: 80px;
        overflow: hidden;
        border-radius: 10px;
        transition: all 0.3s;
        cursor: pointer;
        .ir-md-media-thumbnail-box {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        border: 1px solid #cecece;
        &:hover {
          border: 1px solid #6842ef;
        }
        .ir-md-play-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          font-size: 40px;
          color: white;
          opacity: 1;
          pointer-events: none;
          transition: opacity 0.3s ease-in;
        }
      }
    }
  }
}

.ir-chpr-top-bar-main-cont {
  display: flex;
  align-items: center;
  // gap: 10px;
  justify-content: space-between;
  .ir-chpr-top-bar-cont {
    display: flex;
    align-items: center;
    gap: 10px;

    .ir-chat-top-head-invite-media {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 10px;
      background-color: #cdbfff;
      transition: background-color 0.3s ease;
      // flex-shrink: 0;
      // img{

      // }

      &:hover {
        background-color: #6842ef;
      }
    }
  }
}
.ir-chat-top-head-invite-active{
  background-color: #6842ef !important;
}
.ir-chat-top-head-invite {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  overflow: hidden;
  transition: width 0.3s ease;

  i {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 10px;
    background-color: #cdbfff;
    color: #fff;
    z-index: 2;
    transition: background-color 0.3s ease;
    flex-shrink: 0;
    &:hover {
      background-color: #6842ef;
    }
  }
}

.ir-chpr-case-text {
  margin: 0px;
  padding: 0px;
  font-weight: 700;
  color: #424242;
  text-align: center;
  margin: 16px 0px;
}

.ir-chpr-image-main-cont {
  display: grid;
  grid-template-columns: repeat(4, minmax(80px, 1fr));
  overflow-x: hidden;
  // grid-template-rows: repeat(4, auto);
  row-gap: 30px;
  justify-items: center;
  // max-height: 200px;
  overflow-y: auto;
  margin-top: 30px;

  .ir-chpr-image-box {
    width: 90px;
    height: 90px;
    border-radius: 10px;
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
}


@media (max-width: 768px) {
  .ir-chat-profile-main-container {
    display: none;
  }
  .ir-chat-profile-main-container {
    width: 100%;
  }
  .ir-chat-top-head-invite i {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  .ir-chat-top-head-invite {
    width: 30px;
    height: 30px;
  }
  .ir-chpr-top-bar-main-cont .ir-chpr-top-bar-cont {
    gap:5px
  }
  .ir-md-media-thumbnails-container .ir-md-thumbnail {
    width: 70px;
    height: 70px;
  }
  .ir-md-media-video-cont 
  .ir-md-media-video-thumbnails-container
   .ir-md-video-thumbnail-box .ir-md-video-thumbnail-parent-box {
    width: 70px;
    height: 70px;
  }
  .ir-chpr-image-main-cont .ir-chpr-image-box {
    width: 70px;
    height: 70px;
  }
  .ir-ws-chat-ph-med-cont{
width: 250px;
height: 250px !important;
  }
  
.ir-md-document-content .ir-md-document-item a {
  font-size: 14px;
}

.iks-mint-modal-container{
  padding: 10px !important;
}

.ir-carousel-action-btns {
  width: 30px;
  height: 30px;
  // transform: translateY(150%);
 
}
.ir-carousel-action-btns i {
  font-size: 14px;
}
.iks-mint-cust-modal.ir-chat-inside-modal-container.ir-image-preview-main-modal .iks-mint-modal-container .ir-image-large-thumb-title-container .ir-image-head-thumb-right button {
  width: 30px;
  height: 30px;
  font-size: 14px;
}
.iks-mint-cust-modal.ir-chat-inside-modal-container.ir-image-preview-main-modal .iks-mint-modal-container .ir-image-large-thumb-title-container h3 {
  font-size: 18px;
}
.iks-mint-cust-modal.ir-chat-inside-modal-container.ir-image-preview-main-modal .iks-mint-modal-container .ir-modal-thumb-container-large-image video {
  width: 100%;
}

.group-info-overlay {
  height: calc(100% - 40px);
}
.group-info-overlay .group-users-list {
  height: calc(100% - 315px);
    overflow-y: auto;
}
}


@media screen and (min-width: 768px) and (max-width: 1279px) {
  .ir-chat-top-head-invite i {
    width: 26px;
    height: 26px;
    font-size: 12px;
  }
  .ir-chat-top-head-invite {
    width: 26px;
    height: 26px;
  }
  .ir-chat-profile-main-container {
    padding: 16px 8px;
  }
  .ir-chat-profile-main-container .iks-cine-content {
    height: 170px;
  }
  .iks-chpr-media-cont {
    width: 180px !important;
    height: 180px !important;
}
.ir-chpr-image-main-cont .ir-chpr-image-box {
  width: 40px;
  height: 40px;
}
.ir-chpr-image-main-cont {
  grid-template-columns: repeat(4, minmax(50px, 1fr));
}
.ir-md-media-content .ir-md-media-content-main-img-box .ir-md-media-image-cont .ir-md-media-image-box {
  width: 180px !important;
  height: 180px !important; 
}
.ir-md-media-thumbnails-container .ir-md-thumbnail {
  width: 40px;
  height: 40px;
}
.ir-md-media-thumbnails-container {
  // grid-template-columns: repeat(4, minmax(50px, 1fr));
  grid-template-columns: repeat(4, minmax(auto, 1fr));
}
.ir-md-overlay .ir-md-overlay-content .ir-md-tabs .ir-md-tab-btn {
  padding: 8px;
  font-size: 12px;
}
.ir-md-media-content .ir-md-media-content-main-img-box .ir-md-media-case-text {
  font-size: 12px;
}
.ir-md-media-video-cont .ir-md-media-video-text {
  // font-size: 12px;
}
.ir-md-media-video-cont{
  font-size: 12px;
}
.ir-synopsis-title {
  font-size: 14px;
}
.ir-synopsis-body h6 {
  font-size: 12px;
}
.ir-synopsis-body p {
  font-size: 10px;
}
.ir-md-media-content{
  font-size: 12px;
}
}







@media (min-width: 1280px) {
  .ir-md-media-content-main-img-box {
    .ir-md-media-image-cont {
      .ir-md-media-image-box {
        width: 150px !important;
        height: 150px !important;
      }
    }
  }
  .ir-md-media-thumbnails-container {
    overflow-x: hidden;
    row-gap: 20px !important;
    .ir-md-thumbnail {
      width: 60px;
      height: 60px;
    }
  }
  .ir-md-media-video-thumbnails-container {
    row-gap: 20px !important;
    .ir-md-video-thumbnail-box {
      .ir-md-video-thumbnail-parent-box {
        width: 60px !important;
        height: 60px !important;
        .ir-md-play-icon {
          font-size: 24px !important;
        }
      }
    }
  }

  .iks-chpr-media-cont {
    width: 150px !important;
    height: 150px !important;
  }
  .ir-chpr-image-box {
    width: 60px !important;
    height: 60px !important;
  }

  .ir-chat-profile-main-container {
    max-width: 350px;
  }
  .ir-chat-top-head-invite i {
    width: 32px;
    height: 32px;
    font-size: 12px;
  }
  .ir-chat-top-head-invite {
    width: 32px;
    height: 32px;
  }
  
.ir-md-overlay .ir-md-overlay-content .ir-md-tabs .ir-md-tab-btn {
  padding: 8px;
  font-size: 12px;
}
.ir-chpr-case-text {
  font-size: 12px;
}
.ir-md-overlay .ir-md-overlay-content {
  font-size: 12px;
}
.all-voters-container .all-voters-header .all-voters-title {
  font-size: 14px
  }
  .all-voters-container .voter-list-option .option-title .option-title-text {
font-size: 12px
  }
  .all-voters-container .voter-list-option .voter-list .no-votes {
    font-size: 10px;
  }
  .all-voters-container .voter-list-option .voter-list .voter-item .voter-profile-pic {
    width: 32px;
    height: 32px;
  }
  .all-voters-container .voter-list-option .voter-list .voter-item .voter-username {
    font-size: 12px;
  }
}

@media (min-width: 1600px) {
  .all-voters-container .voter-list-option .voter-list .voter-item .voter-profile-pic {
    width: 36px;
    height: 36px;
  }
  .all-voters-container .voter-list-option .voter-list .voter-item .voter-username {
    font-size: 14px;
  }
  .all-voters-container .all-voters-header .all-voters-title {
    font-size: 16px
    }
    .all-voters-container .voter-list-option .option-title .option-title-text {
      font-size: 16px
        }
        .all-voters-container .voter-list-option .voter-list .no-votes {
          font-size: 12px;
        }
  .ir-md-overlay .ir-md-overlay-content {
    font-size: 16px;
  }
  .ir-chpr-case-text {
    font-size: 16px;
  }
  .ir-md-overlay .ir-md-overlay-content .ir-md-tabs .ir-md-tab-btn {
    padding: 10px;
    font-size: 16px;
  }
  .ir-chat-profile-main-container {
    max-width: 520px;
}
  .ir-chat-top-head-invite {
    width: 40px;
    height: 40px;
  }
  .ir-chat-top-head-invite i {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
  .ir-md-media-content-main-img-box {
    .ir-md-media-image-cont {
      .ir-md-media-image-box {
        width: 400px !important;
        height: 400px !important;
      }
    }
  }
  .ir-md-media-thumbnails-container {
    overflow-x: hidden;
    .ir-md-thumbnail {
      width: 90px !important;
      height: 90px !important;
    }
  }
  .ir-md-media-video-thumbnails-container {
    row-gap: 30px !important;
    max-height: 210px !important;
    .ir-md-video-thumbnail-box {
      .ir-md-video-thumbnail-parent-box {
        width: 90px !important;
        height: 90px !important;
        .ir-md-play-icon {
          font-size: 40px !important;
        }
      }
    }
  }
  .iks-chpr-media-cont {
    width: 400px !important;
    height: 400px !important;
  }
  .ir-chpr-image-box {
    width: 90px !important;
    height: 90px !important;
  }
 
}
