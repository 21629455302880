.ir-ws-hme-prvw-tab-main-container {

	.ir-ws-hme-tabs-header-container {  
		max-width: 650px;
		margin: 0 auto;
		padding: 5px 50px;
		width: fit-content;
		.ir-ws-prvw-tab-title-container {
			padding: 8px;
			cursor: pointer;
			transition: all .5s;

			&:after {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				width: 0%;
				content: '.';
				color: transparent;
				background: #6842ef;
				height: 3px;
				transition: all .5s;
			}
			&:hover {
				text-decoration: none;
				color: #6842ef;
				&:after {
					width: 100%;
				}
			}

			.ir-ws-prvw-tab-img {
				margin: 0 auto;
				padding-bottom: 5px;
				width: 45px;
			}

			.ir-ws-prvw-tab-title {
				font-size: 12px;
				font-style: normal;
				line-height: normal;
				font-weight: 600;
				font-family: 'Inter', sans-serif !important;
				// width: max-content;
				text-align: center;
			}

			&.ir-ws-tab-prvw-active {
				color: #6842ef;
				&:after {
					background-color: #6842EF;
					width: 100%;
  					transform-origin: bottom left;
				}
				// .ir-ws-prvw-tab-title {
				// 	font-weight: 600;
				// }
			}
		}
	}

	.ir-ws-hme-prvw-tabs-panel-main-container {
		padding: 30px 0;

		.ir-ws-hme-prvw-panel-container {
			display: none;

			.ir-ws-hme-prvw-card-container {
				padding-bottom: 30px;
				
				.ir-ws-card-header {
					&.ir-ws-card-header-bg-overlay {
						&:after {
							content: '';
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background: rgba(#000, 0.4);
							border-radius: 16px;
						}
					}
					.ir-ws-prvw-card-thumbnail-container {
						width: 100%;
						height: 100%;
						border-radius: 16px;
						max-width: 100%;
					}

					.ir-ws-prvw-card-play-icon-container {
						cursor: pointer;
						display: inline-block;
						top: 50%;
						left: 50%;
						transform: translateX(-50%) translateY(-50%);
						padding: 15px 35px;
						border-radius: 16px;
						background: radial-gradient(248.23% 49.10% at 45.77% 50.40%, rgba(255, 255, 255, 0.30) 0%, rgba(216, 216, 216, 0.30) 100%);
						opacity: 0.8;
						z-index: 1;

						&:hover {
							opacity: 1;
						}
					}
				}
				
				.ir-ws-card-body {
					.ir-ws-card-body-title {
						margin: 20px 0;
						font-size: 16px;
						font-style: normal;
						font-weight: 700;
						line-height: 24px;
						letter-spacing: 0.1px;
						padding-bottom: 8px;

						&:after {
							position: absolute;
							content: '';
							width: 100%;
							height: 2px;
							left: 0;
							bottom: 0;
							background-color: #EA4C89;
						}
					}

					.ir-ws-card-body-list-container {
						padding-left: 20px;

						.ir-ws-card-list-item {
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
							line-height: 20px; /* 142.857% */
							letter-spacing: 0.2px;
							margin-bottom: 8px;
						}
					}
				}
		
				.ir-ws-card-body-list-container {
					padding-left: 20px;
					margin-top: 10px;
					list-style: disc;
				}
			}

			&.ir-ws-tab-prvw-active {
				display: block;
			}
		}
	}
 
	.ir-ws-hme-prvw-signup-inner-container {
		padding-top: 50px;

		.ir-ws-prvw-signup-content-container {
			p {
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 30px; /* 150% */
				letter-spacing: 0.2px;
				margin-top: 20px;
			}
		}

		.ir-ws-prvw-signup-btn {
			width: max-content;
			border-radius: 5px;
			padding: 15px 40px;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 22px; /* 157.143% */
			letter-spacing: 0.2px;
			position: relative;
			background-size: 400%;
			height: 52px;
			background-size: 400%;
			color: #fff;
			border: none;
			display: inline-block;
			text-align: center;
			
			&:hover {
				text-decoration: none;
			}
		

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				transform: scaleX(0);
				transform-origin: 0 50%;
				width: 100%;
				height: inherit;
				border-radius: inherit;
				background: rgb(43,167,255);
				background: linear-gradient(90deg, rgba(43,167,255,1) 0%, rgba(104,66,239,1) 100%);
				transition: all 0.8s;
				}

			&:hover::before {
				transform: scaleX(1);
			}

			span {
				position: relative;
				z-index: 1;
			}
		}
	}

	.modal__bg {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(28, 28, 28, 0.19);
		backdrop-filter: blur(6px);
		opacity: 1;
		animation-timing-function: ease-out;
		animation-duration: 0.3s;
		animation-name: modal-video;
		-webkit-transition: opacity 0.3s ease-out;
		-moz-transition: opacity 0.3s ease-out;
		-ms-transition: opacity 0.3s ease-out;
		-o-transition: opacity 0.3s ease-out;
		transition: opacity 0.3s ease-out;
		z-index: 1000;
	}
	
	.modal__align {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
	}
	
	.modal__content {
		width: 800px;
		// height: 500px;
		box-shadow: 0px 100px 80px rgba(184, 184, 184, 0.07),
			0px 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112),
			0px 7.779px 7.30492px rgba(0, 0, 0, 0.035),
			0px 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);
		border-radius: 20px;
		background: transparent;
		color: #000;
		margin: 0rem 4rem;
	}
	
	.modal__close {
		background-color: white;
		border-radius: 50%;
		cursor: pointer;
		position: relative;
		bottom: 50px;
		width: 32px;
		height: 32px;
		padding: 0;
	}
	
	.modal__video-align {
		display: flex;
		position: relative;
		bottom: 37px;
	}
	
	.modal__video-style {
		border-radius: 20px;
		z-index: 100;
	}
	
	.modal__spinner {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	
	.modal__spinner {
		animation: spin 2s linear infinite;
		font-size: 40px;
		color: #1b6aae;
	}
	  
}



@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
  
@media screen and (max-width: 800px) {
	.modal__content {
		margin: 0rem 1rem;
		width: 100%;
	}
	.modal__video-style {
		width: 100%;
	}
}
  
@media screen and (max-width: 499px) {
	.modal__content {
		background: transparent;
		height: auto;
	}
	.modal__video-align {
	  	bottom: 0px;
	}
	.modal__video-style {
	  	height: auto;
	}
	.ir-ws-hme-prvw-tab-main-container .ir-ws-hme-tabs-header-container {
		justify-content: space-between;
		width: 100%;
		margin: 0;
		padding: 0px;
			}

			.ir-ws-card-cont-mb{
	height: 520px;
			}
			.ir-ws-hme-prvw-tab-main-container .ir-ws-hme-prvw-tabs-panel-main-container .ir-ws-hme-prvw-panel-container .ir-ws-hme-prvw-card-container {
				padding-inline: 5px;
			}

			.ir-ws-hme-prvw-tab-main-container{
				.ir-ws-hme-prvw-mb-cont{
				padding-top: 0px ;
			}
		}
}

@media (min-width: 768px) {
	.ir-ws-hme-prvw-tab-main-container {
		.ir-ws-hme-tabs-header-container {
			.ir-ws-prvw-tab-title-container {
				margin: 0 15px;
			}
		}
		.ir-ws-hme-prvw-tabs-panel-main-container {
			.ir-ws-hme-prvw-panel-container {
				.ir-ws-hme-prvw-card-container {
					.ir-ws-card-body {
						.ir-ws-card-body-title {
							height: 56px;
						}
					}
				}
			}
		}
	}


}

@media (min-width: 991px) {
	.ir-ws-hme-prvw-tab-main-container {
		.ir-ws-hme-prvw-tabs-panel-main-container {
			.ir-ws-hme-prvw-panel-container.ir-ws-tab-prvw-active {
				display: flex;
			}
		}

		.ir-ws-hme-tabs-header-container {
			.ir-ws-prvw-tab-title-container {
				margin: 0 35px;
			}
		}

		.ir-ws-hme-prvw-signup-inner-container {
			display: flex;

			.ir-ws-prvw-signup-btn {
				width: 320px;
			}

			.ir-ws-prvw-signup-content-container {
				width: 65%;
			}
		}
	}
}

