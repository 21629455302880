.ir-ws-hme-courses-inside-gradient {
  padding: 4px;
  border-radius: 48px 0px 48px 48px;
  background: linear-gradient(90deg, #e69dcb 0%, rgba(247, 169, 160, 0) 100%);

  .ir-ws-hme-courses-main-container {
    border-radius: 48px 0px 48px 48px;
    background: linear-gradient(90deg, #f3f0ff 0%, #f6f7fb 100%);
    .ir-ws-hme-courses-title {
      margin-bottom: 50px;
      font-size: 32px;
      font-weight: 500;
    }
    .swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      cursor: url(../../assets/images/hold-drag.png), auto;
      // cursor: url(https://secure.gravatar.com/avatar/655f4cf189cbb2349e5bad3314c4f3bc?s=114&d=mm&r=g), auto;

      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
      width: 55% !important;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: 150px;
        left: 0;
        width: 100%;
        height: 70%;
        background: rgb(0, 0, 0);
        // filter: blur(100px);
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
        opacity: 0.8;
      }

      .ir-ws-hme-course-carousel-main-container {
        width: 100%;
      }
    }

    .swiper-slide .ir-ws-hme-course-img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .ir-ws-hme-img-thumbnail-container {
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1;

      .ir-ws-hme-img-thumbnail-container-title {
        font-size: 34px;
        font-weight: 600;
        line-height: 40px;
        max-width: 500px;
        text-align: left;
        padding-left: 40px;
        position: relative;
      }

      .ir-ws-hme-img-thumbnail-container-para {
        width: 600px;
        font-size: 14px;
        line-height: 24px;
        text-align: left;
        z-index: 1;
        padding-left: 40px;
      }

      .ir-ws-hme-thumb-container-inner {
        background-color: #010101;
        padding: 30px 40px;
        overflow-x: auto;

        .ir-ws-hme-thumb-division-container {
          margin-right: 40px;
          position: relative;
          cursor: pointer;

          .ir-ws-course-car-thumbnail-item {
            width: 250px;
            border-radius: 10px;
          }

          .ir-ws-hme-course-thumbnail-course-title {
            font-size: 18px;
            font-weight: 700;
            // text-align: left;
            text-align: center;
            margin: 20px 5px 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 250px;
            height: 135px;
            border-radius: 10px;
            background-color: #6842ef;

            span {
              i {
                font-size: 25px;
              }
            }
          }

          .ir-ws-hme-course-duration {
            position: absolute;
            color: #fff;
            bottom: 50px;
            padding: 3px 8px;
            border-radius: 50px;
            left: 5px;
            display: inline-block;
            background-color: #7291f9;
            color: #0d1c33;
            font-size: 12px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
.ir-ws-hme-course-heading-text{
  display: none;
  font-size: 22px;
  font-weight: 600;
  // padding-bottom: 20px;
  margin-bottom: 30px;
  position: relative;
  text-align: center;
  height: 66px;
}
.ir-ws-hme-course-sub-text{
  display: none;
  font-size: 14px;
  color: #737373;
  text-align: center;
}

@media screen and (max-width: 499px) {
  .ir-ws-hme-courses-inside-gradient .ir-ws-hme-courses-main-container .ir-ws-hme-courses-title {
    margin-bottom: 30px;
  }
.ir-ws-hme-courses-inside-gradient .ir-ws-hme-courses-main-container .ir-ws-hme-img-thumbnail-container .ir-ws-hme-thumb-container-inner {
  display: none;
}

.ir-ws-hme-courses-inside-gradient .ir-ws-hme-courses-main-container .ir-ws-hme-img-thumbnail-container .ir-ws-hme-img-thumbnail-container-title {
  // padding-left: 10px;
  // font-size: 24px;
  display: none;
}
.ir-ws-hme-courses-inside-gradient .ir-ws-hme-courses-main-container .swiper-slide {
  width: 95% !important;
  margin: 0 10px
}
.ir-ws-hme-courses-inside-gradient .ir-ws-hme-courses-main-container .ir-ws-hme-img-thumbnail-container .ir-ws-hme-img-thumbnail-container-para {
  // padding-left: 10px;
  // line-height: normal;
  // margin-bottom: 10px;
  // font-size: 12px;
  // width: 100%;
  display: none;
}
.ir-ws-hme-courses-inside-gradient .ir-ws-hme-courses-main-container .ir-ws-hme-img-thumbnail-container {
  top: 0;
}

.ir-ws-hme-courses-inside-gradient .ir-ws-hme-courses-main-container .swiper-slide:after {
  background: none;
}
.ir-ws-hme-courses-inside-gradient .ir-ws-hme-courses-main-container .swiper-slide {
  background: none;
}
.ir-ws-hme-course-heading-text{
  display: block;


}
.ir-ws-hme-course-sub-text{
  display: block;
  margin-top: 30px;
}
}