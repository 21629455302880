.ir-ws-create-course-container {
  margin-top: 5px;
  margin-left: 20px;
  margin-bottom: 10px;
}

.ir-ws-create-course-heading {
  margin-top: 20px;
  font-weight: bold;
  color: #6842ef;
  font-size: 28px;
}
.ir-ws-create-course-container-inputes {
  margin-top: 30px;
  width: 70%;
  display: grid;
  gap: 24px;
  .ir-ws-course-input-title-container {
    display: grid;
    gap: 5px;
  }
}
.ir-ws-course-title-label {
  font-weight: bold;
  font-size: 16px;
  color: #252b42;
}
.ir-ws-course-mandatory-star {
  color: #f03e3e;
}
.ir-ws-course-title-inpute {
  border: 1px solid #ccc;
  &:focus {
    outline: 1px solid #6842ef;
  }
  padding: 15px 50px 15px 15px;
  border-radius: 10px;
}
.ir-ws-course-inpute-latters-container {
  position: relative;

  span {
    font-size: 16px;
    opacity: 50%;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}
/* Text Editor */
.ql-container.ql-snow {
  height: 150px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.ql-toolbar.ql-snow {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.quill.ir-ws-course-description-input {
  border-radius: 10px;
}
.ql-editor.ql-blank {
  background-color: white;
  border-radius: 10px;
}

/*Dropdwon*/
.ir-ws-course-dropdown-menus {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
// .ir-ws-course-dropdown-selection {
//   height: 50px;
//   border-radius: 10px;
//   outline: none;
//   &:focus {
//     outline: none;
//     border: 2px solid #6842ef; /* border color when selected */
//   }
// }

.ir-ws-select-container {
  width: 100%;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  position: relative;
}
.ir-ws-course-dropdown-selection {
  border: 1px solid #ccc;
  appearance: none;
  // padding: 0 30px 0 15px;
  // height: 60px;
  padding-block: 15px;
  padding-left: 16px;
  width: 100%;
  color: #000;
  background-color: #fff;
  font-size: 16px;
  outline: none;
  border-radius: 10px;
  // background-color: #6842ef;
  &:focus {
    outline: none;
    border: 1px solid #6842ef; /* border color when selected */
  }

  option {
    line-height: 40px;
  }
}
.ir-ws-course-dropdown-icon-container {
  width: 50px;
  height: 100%;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 20px;
  }
}
.ir-ws-course-image-upload {
  background: white;
  height: 230px;
  width: 100%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid rgb(189, 189, 189);
}
.ir-ws-course-selected-video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .ir-ws-course-selected-video-image-container {
    background: rgb(220, 220, 220);
    width: 300px;
    border-radius: 10px;
    padding-inline: 10px;
    padding-block: 5px;
  }
  p {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
.custom-svg {
  width: 40px;
  height: 40px;
  opacity: 0.5;
  &:hover {
    stroke: #6842ef;
    opacity: 100%;
  }
}
.ir-ws-instructor-input {
  width: 100%;
}
.ir-ws-save-course-btn-container {
  margin-top: 20px;
  margin-bottom: 50px;
  // width: 40%;
}
.ir-ws-course-image-input {
  display: none;
}

.ir-ws-course-title-uploaded-image {
  height: 230px;
  width: 50%;
}
.ir-ws-course-title-uploaded-video {
  height: 230px;
  width: 50%;
}
.ir-ws-course-textarea {
  border: 1px solid #ccc;
  resize: none;
  height: 120px;
}
/* Second Page Css*/

.ir-ws-text-edit-input {
  width: 30%;
  outline: none;
  border: none;
  padding-left: 20px;
  font-size: 1.1rem;
}
.ir-ws-invalid-field-message {
  justify-content: center;
  color: rgb(255, 99, 99);
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
}

/*newly added css for points section*/

.ir-ws-course-points {
  width: 100%;
}
.ir-ws-course-input-point-container {
  gap: 20px;
  padding-block: 10px;
  &:last-child {
    padding-bottom: 0;
  }
}
.ir-ws-course-delete-points-icon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  &:hover {
    fill: #6842ef;
  }
}
.ir-course-selected-image-video-delete-icon {
  cursor: pointer;
  &:hover {
    fill: #6842ef;
  }
}

.ir-ws-course-add-point-btn-container {
  margin: 0;
  width: 200px;
  .ir-ws-course-add-point-btn {
    width: 200px;
  }
}

.ir-ws-course-added-points-container {
  background-color: #b3b3b3;
  width: 75%;
  border-radius: 10px;

  ul {
    padding-block: 15px;
    margin: 0;
    font-weight: 600;
    .ir-ws-course-added-points-delete-btn-container {
      padding-right: 15px;
      padding-block: 15px;
    }
  }
}

.ir-ws-course-promotion-video-helper-text {
  margin: 0;
  padding: 0;
}

////This course Includes styling///////

.ir-ws-create-course-includes-point-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.ir-ws-create-course-includes-checkbox-container {
  display: flex;
  align-items: center;
  gap: 60px;
}
.ir-ws-create-course-input-checkbox-container {
  align-items: center;
  width: 100%;
  gap: 20px;
}
.ir-ws-create-course-checkbox {
  width: 30px;
  height: 28px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ir-ws-create-course-active-checkbox {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #6842ef;
  cursor: pointer;
}

.ir-ws-create-course-save-btn {
  .ir-ws-banner-btn {
    width: 320px;
  }
}

.ir-ws-create-course-section-btn {
  .ir-ws-banner-btn {
    width: 320px;
  }
}
/////-----------Select tag----------//////////
// .ir-ws-create-course-dropdown-box {
//   background-color: #fff;
//   padding: 15px;
//   border-radius: 10px;
//   justify-content: space-between;
//   gap: 10px;
//   cursor: pointer;
//   border: 1px solid #ccc;
//   width: 330px;
//   transition: all 0.3s;
//   &:hover {
//     border: 1px solid #6842ef;
//   }
//   p {
//     margin: 0;
//   }
// }
// .ir-ws-create-course-category-list {
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
//   margin-top: 10px;
// }
.ir-ws-create-course-dropdown-box {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  width: 330px;
  transition: all 0.3s;
  &:hover {
    border: 1px solid #6842ef;
  }
  p {
    margin: 0;
  }
}

.ir-ws-create-course-category-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.ir-ws-create-course-category-list.open {
  max-height: 1000px; /* Adjust the height according to your content */
}
